import React from "react";
import logo from "../assets/images/logo.png";
import Select from "react-select";
import { playersDetails, selectStyles } from "../App";

const Navbar = ({ selectedPlayer, setSelectedPlayer, handleChange }) => {
  const options = playersDetails.map((player) => ({
    value: player.playerId,
    label: player.playerName,
  }));

  console.log(selectedPlayer, "SEL");

  return (
    <div className=" md:bg-black md:bg-opacity-70">
      <div className="w-full mx-auto max-w-screen-2xl 2xl:max-w-[2100px] px-2 md:px-4 flex items-center justify-between text-center md:text-auto">
        <div className="flex items-center">
          <img
            src={logo}
            alt="LOGO"
            className="max-w-[5rem] max-h-[5rem] p-2.5"
          />
        </div>
        <div className=" flex items-center justify-center ml-4 md:hidden ">
          <span className="text-[#35A4DC] md:text-[3.75rem] text-[2.8rem]">
            [
          </span>
          <span className="md:text-[2rem] font-ubuntuCond text-[1.6rem] text-white">
            CARE SCORE
          </span>
          <span className="text-[#35A4DC] md:text-[3.75rem] text-[2.8rem]">
            ]
          </span>
        </div>
        <div className="hidden">
          <ul className="text-white flex items-center justify-between">
            <li className="px-4 py-2">Tournaments</li>
            <li className="px-4 py-2">Our Team</li>
            <li className="px-4 py-2">About Us</li>
            <li className="px-4 py-2">Shop</li>
            <li className="px-4 py-2">Contact Us</li>
            <li className="px-4 py-2">Sponsors</li>
          </ul>
        </div>
        <div className="hidden md:block">
          <Select
            defaultValue={options[0]}
            value={options.find(
              (option) => option.value === selectedPlayer?.playerId
            )}
            onChange={handleChange}
            options={options}
            styles={selectStyles}
            maxMenuHeight={200}
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </div>
        <div className="md:hidden"></div>
        <div className="md:hidden"></div>
      </div>
    </div>
  );
};

export default Navbar;
