import axios from "axios";
import ShowToastMessage from "../../utils/toast";

export async function fetchData(url, showBoundary) {
  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    showBoundary(error);
    ShowToastMessage("error", error.message);
  }
}
