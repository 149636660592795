import React, { useState } from "react";
import { motion } from "framer-motion";
import Select from "react-select";
import ClipLoader from "react-spinners/ClipLoader";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { getCategory, isObjectEmpty } from "../utils/utility";
import { playersDetails } from "../App";

export const selectBannerStyles = {
  control: (base, state) => ({
    ...base,
    background: "#292929",
    // match with the menu
    borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    // Overwrittes the different states of border
    borderColor: state.isFocused ? "#292929" : "#292929",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    width: "100%",
    fontSize: ".875rem",
    fontWeight: "400",
  }),
  menu: (base) => ({
    ...base,
    // override border radius to match the box
    borderRadius: 0,
    // kill the gap
    marginTop: 0,
  }),
  menuList: (base) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
    fontSize: ".75rem",
    fontWeight: "400",
    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#888",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    console.log({ data, isDisabled, isFocused, isSelected });
    return {
      ...styles,
      backgroundColor: isFocused ? "#4d4d4d" : "#292929",
      color: "#fff",
    };
  },
};

const Banner = ({ loading, playerDetails, selectedPlayer, handleChange }) => {
  const [toggleMeter, setToggleMeter] = useState(false);
  const [toggleBtn, setToggleBtn] = useState(false);

  const options = playersDetails.map((player) => ({
    value: player.playerId,
    label: player.playerName,
  }));

  const handleBtnClick = () => {
    setToggleBtn(!toggleBtn);
  };

  const handleToggle = () => {
    setToggleMeter(!toggleMeter);
  };

  if (loading) {
    return (
      <div className="flex-1 flex flex-col items-center justify-center gap-x-12 py-6">
        <ClipLoader color="#35A4DC" size={50} />
        <span className="text-white mt-1 text-lg tracking-wider">
          Loading...
        </span>
      </div>
    );
  }

  return (
    <div className={`${toggleBtn ? "flex-1 mb-4" : "flex-1"}`}>
      <div className="w-full mx-auto max-w-screen-2xl 2xl:max-w-[2100px] px-2 md:px-4 ">
        <div
          className={`hidden ${
            toggleBtn ? "md:hidden" : "md:flex"
          } items-center justify-start ml-4`}
        >
          <span className="text-[#35A4DC] md:text-[3.75rem] text-[2.8rem]">
            [
          </span>
          <span className="md:text-[2rem] font-ubuntuCond text-[1.6rem] text-white">
            CARE SCORE
          </span>
          <span className="text-[#35A4DC] md:text-[3.75rem] text-[2.8rem]">
            ]
          </span>
        </div>
        <div className="flex items-center justify-center mt-4 px-4 w-full md:hidden">
          <Select
            defaultValue={options[0]}
            value={options.find(
              (option) => option.value === selectedPlayer?.playerId
            )}
            onChange={handleChange}
            options={options}
            styles={selectBannerStyles}
            maxMenuHeight={200}
            className="react-select-container"
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </div>
        <div className="flex flex-col lg:flex-row justify-center xl:gap-x-14">
          <div
            className={`flex flex-col ${
              toggleBtn ? "lg:flex-col md:mt-0" : "lg:flex-row md:mt-[-2.5rem]"
            }   items-center justify-center gap-x-12 py-6  md:py-0 mb-16 md:mb-0`}
          >
            <div>
              <img
                className={`${
                  toggleBtn
                    ? "md:w-[25rem] md:h-[25rem]"
                    : "md:w-[35rem] md:h-[35rem]"
                } `}
                src={selectedPlayer?.playerImg}
                alt=""
                srcset=""
              />
            </div>
            <div className="flex flex-col items-center">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                }}
                transition={{ ease: "easeIn", duration: 0.5 }}
                className="text-white uppercase font-ubuntu md:text-[2.5rem] text-center text-[1.8rem] font-[500] tracking-[0.025rem]"
              >
                {playerDetails?.player && (
                  <>
                    <span className="text-[#35A4DC] md:text-[3.75rem] text-[2rem] ">
                      [
                    </span>
                    {loading ? "Loading..." : playerDetails?.player}
                    <span className="text-[#35A4DC] md:text-[3.75rem] text-[2rem]">
                      ]
                    </span>
                    <span className="text-stroke ml-2 md:text-[3.75rem] text-[2rem]">
                      {selectedPlayer?.playerJersey}
                    </span>
                  </>
                )}
              </motion.div>
              {playerDetails?.dovecaremeter && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{
                    opacity: 1,
                  }}
                  transition={{ ease: "easeIn", duration: 1 }}
                  className="flex flex-col items-center md:gap-4 gap-1 md:mt-6 mt-4"
                >
                  <div
                    className="relative cursor-pointer "
                    onClick={handleToggle}
                  >
                    <div className="radial w-[14rem] h-[14rem] absolute top-0 left-0 rounded-full"></div>

                    <div className="w-[14rem] h-[14rem] border border-accent rounded-full p-2">
                      <CircularProgressbarWithChildren
                        value={playerDetails?.dovecaremeter}
                        background
                        styles={buildStyles({
                          backgroundColor: "#1a1919",
                        })}
                      >
                        <div className=" text-white flex flex-col items-center text-center md:text-[1.5rem] text-[1.2rem] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="8"
                            height="16"
                            viewBox="0 0 8 16"
                            fill="none"
                          >
                            <path
                              d="M0.819584 8.7372H2.1332L0.479942 15.088C0.451132 15.1941 0.445408 15.3061 0.463228 15.4151C0.481049 15.524 0.521919 15.6269 0.582577 15.7155C0.643235 15.8041 0.722 15.876 0.812583 15.9254C0.903166 15.9748 1.00306 16.0003 1.10428 16C1.19082 16.0004 1.27656 15.9818 1.35636 15.9452C1.43617 15.9086 1.50842 15.8548 1.56879 15.787L7.79223 8.96109C7.89132 8.8534 7.95888 8.71591 7.9863 8.56612C8.01373 8.41633 7.99977 8.26101 7.94622 8.11991C7.89267 7.97882 7.80195 7.85833 7.68558 7.77377C7.56922 7.68921 7.43248 7.6444 7.29276 7.64505H6.06905L7.56746 1.86758C7.62369 1.64956 7.63304 1.42028 7.59478 1.19766C7.55652 0.975042 7.47168 0.765112 7.34691 0.58428C7.22213 0.403448 7.0608 0.256615 6.87551 0.155257C6.69023 0.0538988 6.48601 0.000762037 6.27882 0H3.03225C2.7411 0.00162138 2.45825 0.106217 2.22598 0.298152C1.9937 0.490087 1.82448 0.759053 1.74361 1.06485L0.0304168 7.61774C-0.00354928 7.74933 -0.00915577 7.88773 0.0140376 8.02209C0.037231 8.15644 0.0885931 8.28308 0.164091 8.39208C0.239589 8.50108 0.337168 8.58946 0.449167 8.65029C0.561167 8.71112 0.684538 8.74273 0.809595 8.74266L0.819584 8.7372Z"
                              fill="#35A4DC"
                            />
                          </svg>
                          <p className="mt-2 font-mont text-[2.5rem] font-semibold">
                            {!isObjectEmpty(playerDetails) &&
                              playerDetails?.dovecaremeter}
                            %
                          </p>
                          <p className="text-[1.2rem] font-mont font-[500] text-white text-opacity-50">
                            {getCategory(playerDetails?.dovecaremeter)}
                          </p>
                        </div>
                      </CircularProgressbarWithChildren>
                    </div>
                  </div>
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{
                      opacity: toggleMeter ? 1 : 0,
                    }}
                    transition={{ ease: "easeIn", duration: 0.5 }}
                    className={`${
                      toggleMeter ? "flex flex-col items-center" : "hidden"
                    } transition-all ease-in duration-500 gap-4 my-4`}
                  >
                    <div className="flex pl-8 gap-3 xl:gap-4">
                      <div className="md:w-[10rem] font-[700] text-white flex flex-col items-center ">
                        <p className="text-[.675rem] md:text-[.875rem] ">
                          PERFORMANCE:
                        </p>
                        <p className="text-[1.125rem] md:text-[1.5rem]">
                          {playerDetails?.data?.performance} %
                        </p>
                      </div>
                      <div className="md:w-[12rem] font-[700] text-white flex flex-col items-center ">
                        <p className="text-[.675rem] md:text-[.875rem] ">
                          PUBLIC SENTIMENT:
                        </p>
                        <p className="text-[1.125rem] md:text-[1.5rem]">
                          {playerDetails?.data?.sentiment} %
                        </p>
                      </div>
                      <div className="md:w-[12rem] font-[700] text-white flex flex-col items-center ">
                        <p className="text-[.675rem] md:text-[.875rem] ">
                          PSYCHOLOGICAL:
                        </p>
                        <p className="text-[1.125rem] md:text-[1.5rem]">
                          {playerDetails?.data?.psychological} %
                        </p>
                      </div>
                    </div>
                    <div>
                      <button
                        onClick={handleBtnClick}
                        className="py-[.625rem] px-[1rem] rounded-[.25rem] bg-accent text-black"
                      >
                        Details
                      </button>
                    </div>
                  </motion.div>
                </motion.div>
              )}
            </div>
          </div>
          {toggleBtn && (
            <div className="flex flex-col items-center">
              <h3 className="text-white font-ubuntuCond md:text-[2.5rem] text-center text-[1.8rem] font-[500] tracking-[0.025rem]">
                <span className="text-accent md:text-[3.75rem] text-[2rem] ">
                  [
                </span>
                SENTIMENT ANALYSIS
                <span className="text-accent md:text-[3.75rem] text-[2rem]">
                  ]
                </span>
              </h3>
              <div className="md:h-[50rem] md:custom-scroll p-4 overflow-auto flex flex-col gap-6">
                {playerDetails?.debug?.map((tweet) => (
                  <div
                    key={tweet.id}
                    onClick={() => {
                      if (tweet.url) {
                        window.open(tweet.url, "_blank");
                      }
                    }}
                    className="w-[18rem] mob:w-[22rem] xl:w-[28rem] cursor-pointer rounded-[.5rem] text-white bg-grayBg px-6 py-4"
                  >
                    <div className="">
                      <p className="text-[.875rem] font-[400] whitespace-pre-line">
                        {tweet.tweet}
                      </p>
                      {/* <p className="text-accent">#asksrk #othertag1 #othertag2</p> */}
                      {/* <div className="flex items-center gap-4 mt-4">
                      <div className="flex items-center gap-2">
                        <img src={messageIcon} alt="Message Icon" />
                        <p>864</p>
                      </div>
                      <div className="flex items-center gap-2">
                        <img src={repeatIcon} alt="Repeat Icon" />
                        <p>864</p>
                      </div>
                      <div className="flex items-center gap-2">
                        <img src={heartIcon} alt="Heart Icon" />
                        <p>864</p>
                      </div>
                      <div className="flex items-center gap-2">
                        <img src={barIcon} alt="Bar-chart Icon" />
                        <p>864</p>
                      </div>
                      <div className="flex items-center gap-2">
                        <img src={shareIcon} alt="Share Icon" />
                        <p>864</p>
                      </div>
                    </div> */}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Banner;
