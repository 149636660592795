import { Toaster } from "react-hot-toast";
import "./App.css";
import Banner from "./components/banner";
// import BottomNavbar from "./components/bottom-nav";
import Footer from "./components/footer";
import Navbar from "./components/navbar";
import { useEffect, useState } from "react";
import { fetchData } from "./config/apis";

// Player images
import rohit from "./assets/images/rohit.png";
import hardik from "./assets/images/hardik.png";
import kl from "./assets/images/kl.png";
import kuldeep from "./assets/images/kuldeep.png";
import virat from "./assets/images/virat.png";
import shami from "./assets/images/shami.png";
import jadeja from "./assets/images/jadeja.png";
import siraj from "./assets/images/siraj.png";
import kishan from "./assets/images/kishan.png";
import bumrah from "./assets/images/bumrah.png";
import axar from "./assets/images/axar.png";
import shardul from "./assets/images/shardul.png";
import surya from "./assets/images/surya.png";
import shreyas from "./assets/images/shreyas.png";
import gill from "./assets/images/gill.png";
import { useErrorBoundary } from "react-error-boundary";

export const playersDetails = [
  {
    playerId: 0,
    playerName: "Rohit Sharma",
    playerImg: rohit,
    playerJersey: "45",
  },
  {
    playerId: 1,
    playerName: "Hardik Pandya",
    playerImg: hardik,
    playerJersey: "33",
  },
  {
    playerId: 2,
    playerName: "Shubman Gill",
    playerImg: gill,
    playerJersey: "77",
  },
  {
    playerId: 3,
    playerName: "Virat Kohli",
    playerImg: virat,
    playerJersey: "18",
  },
  {
    playerId: 4,
    playerName: "Shreyas Iyer",
    playerImg: shreyas,
    playerJersey: "41",
  },
  {
    playerId: 5,
    playerName: "KL Rahul",
    playerImg: kl,
    playerJersey: "1",
  },
  {
    playerId: 6,
    playerName: "Ravindra Jadeja",
    playerImg: jadeja,
    playerJersey: "8",
  },
  {
    playerId: 7,
    playerName: "Shardul Thakur",
    playerImg: shardul,
    playerJersey: "54",
  },
  {
    playerId: 8,
    playerName: "Jasprit Bumrah",
    playerImg: bumrah,
    playerJersey: "93",
  },
  {
    playerId: 9,
    playerName: "Mohammed Siraj",
    playerImg: siraj,
    playerJersey: "13",
  },
  {
    playerId: 10,
    playerName: "Kuldeep Yadav",
    playerImg: kuldeep,
    playerJersey: "23",
  },
  {
    playerId: 11,
    playerName: "Mohammed Shami",
    playerImg: shami,
    playerJersey: "11",
  },
  {
    playerId: 12,
    playerName: "Axar Patel",
    playerImg: axar,
    playerJersey: "20",
  },
  {
    playerId: 13,
    playerName: "Ishan Kishan",
    playerImg: kishan,
    playerJersey: "32",
  },
  {
    playerId: 14,
    playerName: "Suryakumar Yadav",
    playerImg: surya,
    playerJersey: "63",
  },
];

export const selectStyles = {
  control: (base, state) => ({
    ...base,
    background: "#292929",
    // match with the menu
    borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    // Overwrittes the different states of border
    borderColor: state.isFocused ? "#292929" : "#292929",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    width: "11rem",
    fontSize: ".875rem",
    fontWeight: "400",
    maxHeight: "10rem",
  }),
  menu: (base) => ({
    ...base,
    // override border radius to match the box
    borderRadius: 0,
    // kill the gap
    marginTop: 0,
  }),
  menuList: (base) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
    fontSize: ".75rem",
    fontWeight: "400",
    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#888",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#4d4d4d" : "#292929",
      color: "#fff",
    };
  },
};

function App() {
  const [playerId, setPlayerId] = useState(playersDetails[0].playerId);
  const [playerDetails, setPlayerDetails] = useState({});
  const [selectedPlayer, setSelectedPlayer] = useState(playersDetails[0]);
  const [loading, setLoading] = useState(false);
  const { showBoundary } = useErrorBoundary();

  const handleChange = async (event) => {
    setSelectedPlayer(playersDetails[event.value]);
    await getResponse(event.value);
  };

  const getResponse = async (id = 0) => {
    setLoading(true);

    const response = await fetchData(
      `${process.env.REACT_APP_API_URL}/${id}`,
      showBoundary
    );
    setPlayerDetails(response);
    setLoading(false);
  };

  useEffect(() => {
    getResponse(playerId);
  }, []);

  return (
    <div className="app flex flex-col min-h-screen">
      <Toaster />
      <Navbar
        selectedPlayer={selectedPlayer}
        setSelectedPlayer={setSelectedPlayer}
        handleChange={handleChange}
      />
      <Banner
        loading={loading}
        playerDetails={playerDetails}
        selectedPlayer={selectedPlayer}
        handleChange={handleChange}
      />
      <Footer />
      {/* <BottomNavbar /> */}
    </div>
  );
}

export default App;
