const SeverityLevels = {
  LOW: "Low",
  MEDIUM: "Medium",
  HIGH: "High",
};

export function getCategory(percentage) {
  if (percentage >= 0 && percentage <= 30) {
    return SeverityLevels.LOW;
  } else if (percentage > 30 && percentage <= 75) {
    return SeverityLevels.MEDIUM;
  } else if (percentage > 75) {
    return SeverityLevels.HIGH;
  }
}

export function isObjectEmpty(obj) {
  if (obj) {
    return Object.keys(obj).length === 0;
  }
}
