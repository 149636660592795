import React from "react";

const ErrorPage = () => {
  return (
    <div className="app min-h-[100vh] flex items-center justify-center">
      <div>
        <h2 className="text-[1.25rem] text-white font-[500] ">
          Uh oh! Something went wrong.
        </h2>
        <p className="text-grayBg font-[400] text-[.785rem]">
          There was a problem with your request.
        </p>
        <button
          onClick={() => window.location.reload()}
          className="py-[.5rem] px-[1rem] mt-4 rounded-[.25rem] bg-accent text-black"
        >
          Reload
        </button>
      </div>
    </div>
  );
};

export default ErrorPage;
